import React from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";

import Layout from "components/Layout";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
    }
  `);

  return (
    <Layout pageName="home">
      <Helmet>
        <title>
          PixelCounter : A Specialist Front-End Web Development Studio in
          Brighton
        </title>
        <meta
          name="description"
          content="PixelCounter is a specialist, independent, creative, digital web development studio in Brighton. Focusing on engaging, quirky, and unusual website design and development, search engine optimisation, and social media development."
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            name:
              "PixelCounter Ltd: Specialist front-end development in Brighton UK",
            email: "mail@pixelcounter.com",
            telephone: "+447736930110",
            address: {
              "@type": "PostalAddress",
              streetAddress: "15-17 Middle Street",
              addressLocality: "Brighton",
              addressRegion: "East Sussex",
              postalCode: "BN1 1AL",
              addressCountry: "United Kingdom",
            },
            url: "https://pixelcounter.com",
            logo: {
              "@type": "ImageObject",
              url: "https://pixelcounter.com/logo.png",
              width: 188,
              height: 121,
            },
            image: {
              "@type": "ImageObject",
              url: "https://pixelcounter.com/logo.png",
              width: 188,
              height: 121,
            },
            description:
              "A specialist, independent, creative, digital web development studio based in Brighton. Focusing on engaging, quirky, and unusual website design and development, search engine optimisation, and social media development",
            contactPoint: {
              "@type": "ContactPoint ",
              email: "mail@pixelcounter.com",
              telephone: "+447736930110",
              availableLanguage: ["en", "en-GB", "en-US"],
              areaServed: [
                "West Sussex",
                "East Sussex",
                "Greater London",
                "London",
                "Worldwide",
              ],
            },
            openingHours: "Mo, Tu, We, Th, Fr, Sa, Su 08:00-20:00",
            priceRange: "££",
            awards: [
              "BAFTA-nominated",
              "Awwwards Site of the Day",
              "The FWA Hall of Fame",
              "Design Museum Designs of the Year Digital Award",
            ],
            founder: {
              "@type": "Person",
              name: "John Kavanagh",
              email: "mail@johnkavanagh.co.uk",
              telephone: "+447736930110",
              address: {
                "@type": "PostalAddress",
                streetAddress: "15-17 Middle Street",
                addressLocality: "Brighton",
                addressRegion: "East Sussex",
                postalCode: "BN1 1AL",
                addressCountry: "United Kingdom",
              },
              url: "https://johnkavanagh.co.uk",
              jobTitle: "Freelance front-end web develoepr",
              knowsAbout: [
                "HTML",
                "CSS",
                "JavaScript",
                "React",
                "Gatsby",
                "Web development",
                "Front-end development",
              ],
              givenName: "John",
              familyName: "Kavanagh",
              sameAs: [
                "https://www.linkedin.com/in/johnmkavanagh/",
                "https://www.gatsbyjs.com/creators/people/john-kavanagh",
                "https://twitter.com/johnkavanagh",
                "https://github.com/johnkavanagh",
                "https://stackoverflow.com/users/607004/johnkavanagh",
                "https://app.yunojuno.com/p/johnkavanagh",
                "https://angel.co/u/john-kavanagh-1",
                "https://creativepool.com/john-kavanagh",
              ],
              contactPoint: {
                "@type": "ContactPoint ",
                areaServed: [
                  "West Sussex",
                  "East Sussex",
                  "Greater London",
                  "London",
                  "Worldwide",
                ],
              },
              image: {
                "@type": "ImageObject",
                url: "https://johnkavanagh.co.uk/john-kavanagh.jpg",
                width: 1000,
                height: 1000,
              },
              description:
                "John is a freelance front-end web developer based in Brighton.  He specialises in building highly-performant, responsive, and accessible website and applications with HTML, CSS, and Javascript (often with React)",
              awards: [
                "BAFTA-nominated",
                "Awwwards Site of the Day",
                "The FWA Hall of Fame",
                "Design Museum Designs of the Year Digital Award",
              ],
            },
          })}
        </script>
      </Helmet>
      <Img
        fluid={data.file.childImageSharp.fluid}
        alt="PixelCounter Logo"
        className="logo"
        fadeIn={false}
      />
      <h1 className="title">PixelCounter</h1>
      <p className="text">
        A specialist{" "}
        <a href="https://johnkavanagh.co.uk">front-end web development</a>{" "}
        studio based in Brighton.
      </p>
      <a href="mailto:hello@pixelcounter.com">hello@pixelcounter.com</a>
    </Layout>
  );
};

export default IndexPage;
